import { lngs } from '@/i18n/languages'
import { LocalStorageEnum } from '@/constants/localstorage'

export const setLocale = ({ locale }: { locale: string }): void => {
  if (global?.localStorage) {
    localStorage.setItem(LocalStorageEnum.Locale, locale)
    return
  }

  console.error('setLocale 잘못된 코드 설정!')
}

export const getLocale = ({ fallback = true }: { fallback?: boolean } = {}): string | null => {
  if (global?.localStorage) {
    const locale = localStorage.getItem(LocalStorageEnum.Locale)
    return locale || (fallback ? lngs[0] : null)
  }

  return lngs[0]
}
