import { LocaleEnum } from '@/types/locale'

export const lngs = [LocaleEnum.ko, LocaleEnum.en]

type Return = {
  [LocaleEnum.ko]: {
    [key: string]: __WebpackModuleApi.RequireContext
  }
  [LocaleEnum.en]: {
    [key: string]: __WebpackModuleApi.RequireContext
  }
}

const languages: Return = {
  [LocaleEnum.ko]: {},
  [LocaleEnum.en]: {},
}

const req = require.context('.', true, /.yml$/)

req.keys().forEach((key: string) => {
  const name = key.replace(/\.\/(.+)\.yml$/, '$1')

  lngs.forEach((lng) => {
    languages[lng][name] = req(key)[lng]
  })
})

export default languages
